<template>
  <dl>
    <dt>
      <img :src="block?.bgimg" alt="" />
    </dt>
    <dd>
      <div class="div_text bz" v-html="block.bz">
      </div>
    </dd>
  </dl>
</template>

<script>
export default {
  props: {
    block: {
      type: Object,
      default: () => {},
    },
  },
}
</script>

<style lang="less" scoped>
/deep/.div_text.bz {
  img {
    max-width: 200px;
    height: auto;
  }
}
</style>
