<template>
  <div class="news">
    <!-- banner -->
    <div class="second_banner">
      <img :src="bannerImg" :alt="sectionTitle" />

      <div class="div_beard">
        <p>
          <a
            v-for="(bread, index) in breads"
            :key="index"
            @click="$router.push(bread.path)"
          >
            {{ bread.name }}
          </a>
        </p>
      </div>
    </div>

    <!-- tabs -->
    <div class="second_nav">
      <ul>
        <li
          :class="{
            on: activeTabId === tab.id,
          }"
          v-for="tab in roles"
          :key="tab.id"
        >
          <a @click="handleRouterPush(tab.path)">
            <span>{{ tab.title }}</span>
          </a>
        </li>
      </ul>
    </div>

    <!-- content -->
    <slot></slot>
  </div>
</template>

<script>
import { mapState } from 'vuex'
// import portalApi from '../../../../lvya-common/src/api/eos/portal'
import { useAddColumnCount } from '../utils/useAddArticleCount'

export default {
  props: {},
  computed: {
    ...mapState({
      roles: (state) => state.yzsyStore.roles,
    }),
    bannerImg() {
      return this.roles.find((item) => item.id === this.activeTabId)?.bgimg
    },
    sectionTitle() {
      return this.roles[0]?.title
    },
    activeTabId() {
      return this.roles?.find((item) => item.path === this.$route.path)?.id
    },
    breads() {
      const activeTab = this.roles?.find(
        (item) => item.path === this.$route.path
      )
      console.log(activeTab, 'activeTab')
      return [
        { name: '角色', path: this.roles[0]?.tzurl },
        { name: activeTab?.title, path: activeTab?.path },
      ]
    },
  },
  watch: {
    activeTabId: {
      async handler(newVal) {
        if (newVal) {
          useAddColumnCount(newVal)
        }
      },
      immediate: true,
    },
  },
  methods: {
    handleRouterPush(path) {
      this.$router.push(path)
    },
  },
}
</script>

<style lang="less" scoped>
@import "../style/common.css";
@import "../style/global.css";
@import "../style/role.css";
@import "../style/news.css";

/deep/.el-pagination.is-background .el-pager li:not(.disabled):hover {
  color: #b82e1b !important;
}
/deep/.el-pagination {
  margin-top: 1rem;
  .btn-next,
  .btn-prev {
    background: transparent;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 2.6rem;
    height: 0.6rem;
    line-height: 0.6rem;
    text-align: center;
    border: 1px solid #eaeaea;
    font-size: 0.18rem;
    border-radius: 5px;
    &:not(.disabled):hover {
      background: #b82e1b;
      color: #fff;
    }
  }
  .el-pager {
    &li:not(.disabled):hover {
      color: #b82e1b !important;
    }
    .more {
      background: transparent;
    }
    .number {
      border-radius: 5px;
      background: transparent;
      :hover {
        color: #b82e1b !important;
      }
      &.active {
        background-color: #b82e1b !important;
      }
    }
  }
}
</style>
