
<template>
  <ul class="ul_icons ul_bg">
    <li class="cloud_platform" v-for="item in blocks" :key="item.id">
      <div class="div_img">
        <img :src="item.bgimg" alt="" />
      </div>
      <span
        ><p>{{ item.title }}</p>
      </span>
      <div class="div_fix">
        <img :src="item.imgbz" alt="" />
      </div>
    </li>
  </ul>
</template>

<script>
export default {
  props: {
    blocks: {
      type: Array,
      default: () => [],
    },
  },
}
</script>

<style>
</style>
