import portalApi from '../../../../lvya-common/src/api/eos/portal'

// 文章访问量增加
export async function useAddArticleCount(typeId) {
  await portalApi.addCount({
    noSchoolId: true,
    id: typeId,
    type: '2',
  })
}

// 栏目访问量增加
export async function useAddColumnCount(typeId) {
  await portalApi.addCount({
    noSchoolId: true,
    id: typeId,
    type: '3',
  })
}
