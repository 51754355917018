<template>
  <ul class="ul_icons ul_bg">
    <li class="cloud_platform" v-for="item in blocks" :key="item.id">
      <div class="">
        <img :src="item.bgimg" alt="" />
      </div>
      <span>
        <p>
          {{ item.title }}<br />
          {{ item.bz }}
        </p>
      </span>
    </li>
  </ul>
</template>

<script>
export default {
  props: {
    blocks: {
      type: Array,
      default: () => [],
    },
  },
}
</script>

<style>
</style>
